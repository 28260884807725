import React, { useState } from 'react';
import { saveSupplier } from '../../api/suppliers';
import { useTranslation } from 'react-i18next';

function AddSupplierModal({ show, setShow, setSuppliers }) {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState([]);

    const handleClose = () => setShow(false);

    const handleSubmit = async () => {
        const newSupplier = {
            name,
            email,
            phone,
        };

        try {
            const savedSupplier = await saveSupplier(newSupplier);
            setSuppliers((prevSuppliers) => [...prevSuppliers, savedSupplier]);
            setShow(false);
        } catch (error) {
            setErrors(error.message);
        }
    };

    return (
        <div className={`modal fade ${show ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                <div className="modal-content py-3 px-4 border-0 shadow-lg">
                    <div className="modal-header pb-0 border-0 d-flex align-items-center">
                        <h4 className="modal-title bold m-0">{t('actions.add_supplier')}</h4>
                        <span className="close ms-auto m-0 text-secondary" onClick={handleClose} style={{ fontSize: '25pt', fontWeight: '300' }}>
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className='col-md-6 p-2'>
                                <input type="text" className='form-control' placeholder={t('suppliers.name')} value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input type="email" className='form-control' placeholder={t('suppliers.email')} value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input type="text" className='form-control' placeholder={t('suppliers.phone')} value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                        {errors && (
                            <div className="text-danger small">
                                {errors.map((errorMessage, index) => (
                                    <span key={index}>{errorMessage}</span>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='modal-footer border-0'>
                        <button className='btn btn-primary rounded' onClick={handleSubmit}>{t('actions.add')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSupplierModal;
