import { apiUrl } from './config';

export const getInvoices = async () => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/invoices`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) throw new Error('Error fetching invoices');
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const saveInvoice = async (formData) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/invoices`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        });
        if (!response.ok) throw new Error('Error saving invoice');
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
