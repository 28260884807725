import React, { useState, useEffect } from 'react';
import { saveOrder } from '../api/orders';
import { getSuppliers } from '../api/suppliers';
import { getProducts } from '../api/products';
import { useTranslation } from 'react-i18next';

function AddOrderModal({ orders, setOrders, showAddOrderModal, setShowAddOrderModal }) {
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [productList, setProductList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [errors, setErrors] = useState([]);
    
    const { t } = useTranslation();

    useEffect(() => {
        const fetchSuppliers = async () => {
            const data = await getSuppliers();
            setSuppliers(data);
        };

        const fetchProducts = async () => {
            const data = await getProducts();
            setProductList(data);
        };

        fetchSuppliers();
        fetchProducts();
    }, []);

    const handleProductChange = (productId, quantity) => {
        setSelectedProducts(prevProducts => {
            const existingProduct = prevProducts.find(product => product.product_id === productId);
            if (existingProduct) {
                return prevProducts.map(product =>
                    product.product_id === productId ? { ...product, quantity } : product
                );
            }
            return [...prevProducts, { product_id: productId, quantity }];
        });
    };

    const handleCloseAddOrderModal = () => {
        setShowAddOrderModal(false);
    };

    const handleSubmit = async () => {
        const order = {
            "company_name": companyName,
            "email": email,
            "order_number": orderNumber,
            "products": selectedProducts,
        };

        try {
            const newOrder = await saveOrder(order);
            setOrders([newOrder, ...orders]);
            setShowAddOrderModal(false);

            setCompanyName('');
            setEmail('');
            setOrderNumber('');
            setSelectedProducts([]);
            setErrors([]);

        } catch (error) {
            setErrors(error.message);
        }
    };

    return (
        <>
            <div className={`modal fade ${showAddOrderModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                    <div className="modal-content py-3 px-4 border-0 shadow-lg" style={{ maxHeight: 800, overflow: 'auto' }}>
                        <div className="modal-header pb-0 border-0 d-flex align-items-center">
                            <div>
                                <h4 className="modal-title bold m-0">{t('הוסף הזמנה')}</h4>
                            </div>
                            <span type="button" className="close ms-auto m-0 text-secondary" onClick={handleCloseAddOrderModal} style={{ fontSize: '25pt', fontWeight: '300' }}>
                                <span aria-hidden="true">&times;</span>
                            </span>
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-md-6 p-2'>
                                    <input type="text" className='form-control' placeholder={t('שם חברה')} value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                </div>
                                <div className='col-md-6 p-2'>
                                    <select className='form-control' value={email} onChange={(e) => setEmail(e.target.value)}>
                                        <option value="">{t('בחר מיל ספק')}</option>
                                        {suppliers.map(supplier => (
                                            <option key={supplier.id} value={supplier.email}>{supplier.email}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-md-6 p-2'>
                                    <input type="text" className='form-control' placeholder={t('מספר הזמנה')} value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
                                </div>
                                <div className='col-md-12 p-2'>
                                    <label>{t('מוצרים')}</label>
                                    {productList.map(product => (
                                        <div key={product.id} className='d-flex align-items-center mb-2'>
                                            <input
                                                type="number"
                                                className='form-control me-2'
                                                placeholder={t('Quantity')}
                                                onChange={(e) => handleProductChange(product.id, parseInt(e.target.value))}
                                            />
                                            <span>{product.name}</span>
                                        </div>
                                    ))}
                                </div>
                                {errors && (
                                    <div className="text-danger small">
                                        {errors.description && errors.description.map((errorMessage, index) => (
                                            <span key={index}>{errorMessage}</span>
                                        ))}
                                        {errors.name && errors.name.map((errorMessage, index) => (
                                            <span key={index}>{errorMessage}</span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='modal-footer border-0'>
                            <button className='btn btn-primary rounded' onClick={handleSubmit}>{t('הוסף הזמנה')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddOrderModal;
