import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header/Header';
import { getProducts } from '../../api/products';
import { getSuppliers } from '../../api/suppliers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faBars, faEdit } from '@fortawesome/free-solid-svg-icons';
import AddProductModal from '../../components/AddProductModal';
import AddOrderModal from '../../components/AddOrderModal';
import UpdateSupplierModal from '../../components/UpdateSupplierModal'; // Import the UpdateSupplierModal component
import productExampleImage from '../../assets/img/manhattan-logo.jpg';
import { useTranslation } from 'react-i18next';
import ProductTable from '../../components/ProductTable';

function Products() {
    const [products, setProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [showAddOrderModal, setShowAddOrderModal] = useState(false);
    const [showUpdateSupplierModal, setShowUpdateSupplierModal] = useState(false); // State for UpdateSupplierModal
    const [selectedSupplier, setSelectedSupplier] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        const fetchedProducts = async () => {
            try {
                const fetchedProducts = await getProducts();
                setProducts(fetchedProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchedSuppliers = async () => {
            try {
                const fetchedSuppliers = await getSuppliers();
                setSuppliers(fetchedSuppliers);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchedProducts();
        fetchedSuppliers();
    }, []);

    const openAddProductModal = () => {
        setShowAddProductModal(true);
    };

    const openAddOrderModal = () => {
        setShowAddOrderModal(true);
    };

    const openUpdateSupplierModal = (supplier) => {
        setSelectedSupplier(supplier);
        setShowUpdateSupplierModal(true);
    };

    return (
        <>
            <div className='d-flex justify-content-center pt-3 pb-4 '>
                <button className='btn btn-basic bg-white shadow-sm medium' onClick={openAddProductModal}>
                    <span className='text-primary'>
                        <FontAwesomeIcon icon={faPlus} className='pe-1' /> {t('הוספת מוצר')}
                    </span>
                </button>
                <button className='btn btn-basic bg-white shadow-sm medium ms-2' onClick={openAddOrderModal}>
                    <span className='text-primary'>
                        <FontAwesomeIcon icon={faPlus} className='pe-1' /> {t('הוספת הזמנה')}
                    </span>
                </button>
                <button className='btn btn-basic bg-white shadow-sm medium ms-2' onClick={() => openUpdateSupplierModal(suppliers[0])}>
                    <span className='text-primary'>
                        <FontAwesomeIcon icon={faPlus} className='pe-1' /> {t('הוסף ספק')}
                    </span>
                </button>
            </div>

            <div className="container">
                <div className="row pt-4">
                    {products.map(product => (
                        <div className="col-md-3" key={product.id}>
                            <div className="rounded bg-white border-0 hover-lg pointer w-100">
                                <img src={productExampleImage} className='product-card-image' alt="" />
                                <div className='p-3'>
                                    <h5>{product.name}</h5>
                                    <p className='medium text-muted'>{product.description}</p>
                                    <h5 className='text-primary' style={{ fontWeight: '600' }}>${product.price}</h5>
                                    <button className='btn btn-warning' onClick={() => openUpdateSupplierModal(product.supplier)}>{t('עדכן ספק')}</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <AddProductModal
                products={products}
                setProducts={setProducts}
                showAddProductModal={showAddProductModal}
                setShowAddProductModal={setShowAddProductModal}
            />
            <AddOrderModal
                orders={[]} // You'll need to handle the orders state similarly to products
                setOrders={() => {}} // Update this to handle setting orders
                showAddOrderModal={showAddOrderModal}
                setShowAddOrderModal={setShowAddOrderModal}
            />
            <UpdateSupplierModal
                supplier={selectedSupplier}
                setSupplier={(updatedSupplier) => {
                    setSuppliers((prevSuppliers) => prevSuppliers.map(s => s.id === updatedSupplier.id ? updatedSupplier : s));
                }}
                showUpdateSupplierModal={showUpdateSupplierModal}
                setShowUpdateSupplierModal={setShowUpdateSupplierModal}
            />

            <ProductTable />
        </>
    );
}

export default Products;
