import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getTasksByUser } from '../../api/tasks';
import TaskModal from '../Tasks/TaskModal';

function NimerTable() {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [showTasksModal, setShowTasksModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const tasksData = await getTasksByUser(2);
        setTasks(tasksData);
      } catch (error) {
        console.error('Failed to fetch tasks:', error);
      }
    };
    fetchTasks();
  }, []);

  const handleRowClick = (task) => {
    setSelectedTask(task);
    setShowTasksModal(true);
  };

  return (
    <div className="bg-white rounded p-3 shadow-sm">
      <h6 className="bold mb-3">משימות נימר</h6>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>שם משימה</th>
            <th>סטטוס משימה</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr key={index} onClick={() => handleRowClick(task)}>
              <td>{task.name}</td>
              <td>{task.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedTask && (
        <TaskModal
          showTasksModal={showTasksModal}
          setShowTasksModal={setShowTasksModal}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          tasks={tasks}
          setTasks={setTasks}
          projectMembers={[]} // Add project members here if needed
          setProjectMembers={() => {}} // Add setter for project members if needed
        />
      )}
    </div>
  );
}

export default NimerTable;
