import { apiUrl } from './config';

const getSuppliers = async () => {
    try {
      

        const response = await fetch(apiUrl + '/suppliers', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
           
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }
}

const updateSupplier = async (supplier) => {
  try {
      const token = localStorage.getItem('accessToken');

      const response = await fetch(apiUrl + '/suppliers/' + supplier.id, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify(supplier)
      });

      const responseData = await response.json();

      return responseData;

  } catch (error) {
      return error;
  }
}


const saveSupplier = async (supplier) => {
    try {
        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/suppliers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(supplier)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }
}


const deleteSupplier = async (supplier_id) => {
    try {
        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/suppliers/' + supplier_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }
}

export { getSuppliers, saveSupplier, updateSupplier, deleteSupplier };
