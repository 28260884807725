import React, { useState, useEffect } from 'react'
import { getUserInfo } from '../../api/user';
import { getProjectInfo } from '../../api/project';
import NewNotifications from '../../components/Home/NewNotifications';
import Calendar from 'react-calendar';
import { getTasks } from '../../api/tasks';
import KamilTable from '../../components/Dashboard/kamilTable';
import NimerTable from '../../components/Dashboard/nimerTable';
import NitsanTable from '../../components/Dashboard/nitsanTable';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import {  Line , Pie } from 'react-chartjs-2';
import TaskModal from '../../components/Tasks/TaskModal';
import DayTasksModal from '../../components/Tasks/DayTasksModal'; // Import DayTasksModal component

function DashboardProject() {

    const { t } = useTranslation();
    const [projectInfo, setProjectInfo] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [value, onChange] = useState(new Date());

    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [showTasksModal, setShowTasksModal] = useState(false);
    const [showDayTasksModal, setShowDayTasksModal] = useState(false);
    const [tasksForDay, setTasksForDay] = useState([]);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
                console.log(fetchedUserInfo);
            } catch (error) {
                console.error("Error fetching :", error);
            }
        }
        const fetchProjectInfo = async () => {
            try {
                const fetchedProjectInfo = await getProjectInfo();
                setProjectInfo(fetchedProjectInfo);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        const fetchTasks = async () => {
            try {
                const fetchedTasks = await getTasks();
                setTasks(fetchedTasks.filter(task => task.status === 'todo')); // Filter tasks with 'todo' status
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
        fetchUserInfo();
        fetchProjectInfo();
        const isRTL = i18n.language === 'he';
        document.body.dir = isRTL ? 'rtl' : 'ltr';
    }, [i18n.language]);

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };


    const handleTaskClick = (task) => {
        setSelectedTask(task);
        setShowTasksModal(true);
    };

    const handleDayClick = (date) => {
        const tasksForSelectedDay = tasks.filter(task => {
            const taskDate = new Date(task.due_date).toDateString();
            return taskDate === date.toDateString();
        });
        setTasksForDay(tasksForSelectedDay);
        setShowDayTasksModal(true);
    };
 

    const lineChartData = {
        labels: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי'],
        datasets: [
          {
            label: ' פדיון',
            data: [550161, 666549, 649333, 585268, 688470, 609643, 563308],
            fill: true,
            backgroundColor: 'rgba(0,158,253, 0.2)',
            borderColor: 'rgb(0,158,253)',
            tension: 0.4,
          },
        ],
      };

   
        const data = {
          labels: [
            '10bis',
            'משלוחה',
            'סודקסו',
            'Wolt',
            'Restaurant',
            'TabitOrder',
            'TD'
          ],
          datasets: [
            {
              label: 'מכירות יולי 2024',
              data: [
                3611,  // 10bis
                11818, // משלוחה
                18825, // סודקסו
                166141, // Wolt
                28651, // Restaurant
                41905, // TabitOrder
                930 // TD
              ],
              backgroundColor: [
                '#FF6384', // 10bis
                '#36A2EB', // משלוחה
                '#FFCE56', // סודקסו
                '#FF9F40', // Wolt
                '#4BC0C0', // Restaurant
                '#9966FF', // TabitOrder
                '#FF6384'  // TD
              ],
              hoverBackgroundColor: [
                '#FF6384', // 10bis
                '#36A2EB', // משלוחה
                '#FFCE56', // סודקסו
                '#FF9F40', // Wolt
                '#4BC0C0', // Restaurant
                '#9966FF', // TabitOrder
                '#FF6384'  // TD
              ]
            }
          ]
        };


    const options = {
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
        },
      };




    return (
        <div  className="container" dir={i18n.language === 'he' ? 'rtl' : 'ltr'}>

            <div className='pt-3'>
                <h3 className='mt-3 fw-500'>{t('greeting.welcome')}, {userInfo && userInfo.name.split(" ")[0]}!</h3>
            </div>

            <div className="row pt-3">
            <div className="col-md-3">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <NimerTable  />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <NitsanTable />
                    </div>
                </div>
            <div className="col-md-3">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <KamilTable  />
                    </div>
                </div>
              
               

              
         
          
                <div className="col-md-8">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <Line data={lineChartData} options={options} />
                    </div>
                </div>
                <div className="col-md-6">
                <div className="bg-white rounded p-3 mt-3 shadow-sm">
                    <h2>מכירות לפי מקור הזמנה - יולי 2024</h2>
                    <Pie data={data} />
                </div>
               </div>
                <div className="col-md-4">
                <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <h6 className='bold mb-3'>{t('card_title.my_calendar')}</h6>
                        <Calendar
                            onChange={onChange}
                            value={value}
                            className="w-100 border-0 px-4 my-4"
                            tileContent={({ date, view }) => {
                                if (view === 'month') {
                                    const dayTasks = tasks.filter(task => {
                                        const taskDate = new Date(task.due_date).toDateString();
                                        return taskDate === date.toDateString();
                                    });
                                    return dayTasks.length > 0 ? <span className="dot"></span> : null;
                                }
                            }}
                            onClickDay={handleDayClick}
                        />
                    </div>
                </div>

                </div>
                {showTasksModal && selectedTask && (
                <TaskModal
                    showTasksModal={showTasksModal}
                    setShowTasksModal={setShowTasksModal}
                    selectedTask={selectedTask}
                    setSelectedTask={setSelectedTask}
                    tasks={tasks}
                    setTasks={setTasks}
                />
            )}
            {showDayTasksModal && (
                <DayTasksModal
                    showDayTasksModal={showDayTasksModal}
                    setShowDayTasksModal={setShowDayTasksModal}
                    tasksForDay={tasksForDay}
                    handleTaskClick={handleTaskClick}
                />
            )}
            </div>

       
    )
}

export default DashboardProject