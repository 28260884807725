import React, { useState, useEffect } from 'react';
import { saveProduct } from '../api/products';
import { getSuppliers } from '../api/suppliers';
import { useTranslation } from 'react-i18next';

function AddProductModal({ products, setProducts, showAddProductModal, setShowAddProductModal }) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [maxquantity, setMaxquantity] = useState('');
    const [quantity, setQuantity] = useState('');
    const [supplierId, setSupplierId] = useState('');  // State to hold selected supplier ID
    const [suppliers, setSuppliers] = useState([]);  // State to hold fetched suppliers
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (showAddProductModal) {
            fetchSuppliers();
        }
    }, [showAddProductModal]);

    const fetchSuppliers = async () => {
        try {
            const fetchedSuppliers = await getSuppliers();
            setSuppliers(fetchedSuppliers);
        } catch (error) {
            setErrors(error.message);
        }
    };

    const handleCloseAddProductModal = () => {
        setShowAddProductModal(false);
    };

    const handleSubmit = async () => {
        const product = {
            name,
            description,
            maxquantity,
            quantity,
            price,
            supplier_id: supplierId  // Include supplier ID
        };

        try {
            const newProduct = await saveProduct(product);
            setProducts([newProduct, ...products]);
            setShowAddProductModal(false);

            setName('');
            setDescription('');
            setPrice('');
            setMaxquantity('');
            setQuantity('');
            setSupplierId('');  // Reset supplier ID
            setErrors([]);

        } catch (error) {
            setErrors(error.message);
        }
    };

    return (
        <>
            <div className={`modal fade ${showAddProductModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                    <div className="modal-content py-3 px-4 border-0 shadow-lg" style={{ maxHeight: 800, overflow: 'auto' }}>
                        <div className="modal-header pb-0 border-0 d-flex align-items-center">
                            <div>
                                <h4 className="modal-title bold m-0">הוספת מוצר</h4>
                            </div>
                            <span type="button" className="close ms-auto m-0 text-secondary" onClick={handleCloseAddProductModal} style={{ fontSize: '25pt', fontWeight: '300' }}>
                                <span aria-hidden="true">&times;</span>
                            </span>
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-md-6 p-2'>
                                    <input type="text" className='form-control' placeholder='שם מוצר' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className='col-md-6 p-2'>
                                    <input type="number" className='form-control' placeholder='מחיר' value={price} onChange={(e) => setPrice(e.target.value)} />
                                </div>
                                <div className='col-md-6 p-2'>
                                    <input type="text" className='form-control' placeholder='כמות מקסימלית' value={maxquantity} onChange={(e) => setMaxquantity(e.target.value)} />
                                </div>
                                <div className='col-md-6 p-2'>
                                    <input type="number" className='form-control' placeholder='כמות נוכחית' value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                                </div>
                                <div className='col-md-12 p-2'>
                                    <textarea className='form-control' style={{ height: 130 }} placeholder='מידע נוסף' value={description} onChange={(e) => setDescription(e.target.value)} />
                                </div>

                                <div className='col-md-12 p-2'>
                                    <select className='form-control' value={supplierId} onChange={(e) => setSupplierId(e.target.value)}>
                                        <option value=''>בחר ספק</option>
                                        {suppliers.map(supplier => (
                                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                        ))}
                                    </select>
                                </div>

                                {errors && (
                                    <div className="text-danger small">
                                        {errors.description && errors.description.map((errorMessage, index) => (
                                            <span key={index}>{errorMessage}</span>
                                        ))}
                                        {errors.name && errors.name.map((errorMessage, index) => (
                                            <span key={index}>{errorMessage}</span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='modal-footer border-0'>
                            <button className='btn btn-primary rounded' onClick={handleSubmit}>הוספת מוצר חדש</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddProductModal;
