import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faBars, faEdit } from '@fortawesome/free-solid-svg-icons';
import { getSuppliers, deleteSupplier } from '../api/suppliers';
import AddSupplierModal from '../components/suppliers/AddSupplierModal';
import EditSupplierModal from '../components/suppliers/EditSupplierModal';
import ViewSupplierModal from '../components/suppliers/ViewSupplierModal';
import { useTranslation } from 'react-i18next';

function Suppliers() {
    const { t } = useTranslation();
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [showAddSuppliersModal, setShowAddSuppliersModal] = useState(false);
    const [showEditSuppliersModal, setShowEditSuppliersModal] = useState(false);
    const [showViewSupplierModal, setShowViewSupplierModal] = useState(false);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const fetchedSuppliers = await getSuppliers();
                setSuppliers(fetchedSuppliers);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchSuppliers();
    }, []);

    const openAddSupplierModal = () => {
        setShowAddSuppliersModal(true);
    };

    const handleDeleteSupplier = async (supplier_id) => {
        try {
            await deleteSupplier(supplier_id);
            const updatedSuppliers = suppliers.filter(supplier => supplier.id !== supplier_id);
            setSuppliers(updatedSuppliers);
        } catch (error) {
            console.error('Error deleting supplier:', error);
        }
    };

    const openViewSupplierModal = (supplier) => {
        setSelectedSupplier(supplier);
        setShowViewSupplierModal(true);
    };

    const openEditSupplierModal = (supplier) => {
        setSelectedSupplier(supplier);
        setShowEditSuppliersModal(true);
    };

    return (
        <>
            <div className="container">
                <div className='d-flex justify-content-center pt-3 pb-4'>
                    <button className='btn btn-basic bg-white shadow-sm medium' onClick={openAddSupplierModal}>
                        <span className='text-primary'>
                            <FontAwesomeIcon icon={faPlus} className='pe-1' /> {t('actions.add_supplier')}
                        </span>
                    </button>
                </div>

                <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
                    <div className='table-responsive pt-3'>
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th className='text-secondary bg-transparent fw-500'>{t('suppliers.name')}</th>
                                    <th className='text-secondary bg-transparent fw-500'>{t('suppliers.email')}</th>
                                    <th className='text-secondary bg-transparent fw-500'>{t('suppliers.phone')}</th>
                                    <th className='text-center text-secondary bg-transparent fw-500'>{t('actions.options')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {suppliers.map(supplier => (
                                    <tr key={supplier.id}>
                                        <td className='bg-transparent'>{supplier.name}</td>
                                        <td className='bg-transparent'>{supplier.email}</td>
                                        <td className='bg-transparent'>{supplier.phone}</td>
                                        <td className='bg-transparent'>
                                            <div className="h-100 d-flex align-items-center justify-content-center">
                                                <div className='px-1'>
                                                    <button className='btn btn-basic ' onClick={() => openViewSupplierModal(supplier)}>
                                                        <FontAwesomeIcon icon={faBars} />
                                                    </button>
                                                </div>
                                                <div className='px-1'>
                                                    <button className='btn btn-basic ' onClick={() => openEditSupplierModal(supplier)}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                </div>
                                                <div className='px-1'>
                                                    <button className='btn btn-basic  text-danger' onClick={() => handleDeleteSupplier(supplier.id)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <AddSupplierModal
                    show={showAddSuppliersModal}
                    setShow={setShowAddSuppliersModal}
                    setSuppliers={setSuppliers}
                />

                <EditSupplierModal
                    show={showEditSuppliersModal}
                    setShow={setShowEditSuppliersModal}
                    suppliers={suppliers}
                    setSuppliers={setSuppliers}
                    selectedSupplier={selectedSupplier}
                />

                <ViewSupplierModal
                    show={showViewSupplierModal}
                    setShow={setShowViewSupplierModal}
                    selectedSupplier={selectedSupplier}
                />
            </div>
        </>
    );
}

export default Suppliers;
