import React, { useEffect , useState} from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveLead } from '../../../api/leads';
import { getContacts } from '../../../api/contacts';
import { useTranslation } from 'react-i18next';

function AddLeadModal({ leads, setLeads, showAddLeadModal, setShowAddLeadModal, contacts, setContacts }) {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [status, setStatus] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);
    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const fetchedContacts = await getContacts();
                setContacts(fetchedContacts);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchContacts();
    }, []);

    const handleCloseAddLeadModal = () => {
        setShowAddLeadModal(false);
    };

    const handleAddLead = async (contact) => {
        try {
            const newLead = await saveLead(contact.id);
            const updatedLeads = [newLead, ...leads];
            setLeads(updatedLeads);
            setShowAddLeadModal(false);
            setErrors([]);
        } catch (error) {
            console.log("Error while trying to add a lead.");
            setErrors(error.message);
        }
    }

    return (
        <>
            <div className={`modal fade ${showAddLeadModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                    <div className="modal-content py-3 px-4 border-0 shadow-lg">
                        <div className="modal-header pb-0 border-0 d-flex align-items-center">
                            <div>
                                <h4 className="modal-title bold m-0">הוספת עובד</h4>
                            </div>
                            <span type="button" className="close ms-auto m-0 text-secondary" onClick={handleCloseAddLeadModal} style={{ fontSize: '25pt', fontWeight: '300' }}>
                                <span aria-hidden="true">&times;</span>
                            </span>
                        </div>
                        <div className='modal-body' style={{ maxHeight: 520, overflow: 'auto' }}>
                        <div className='row'>
                                <div className='col-md-6 p-2'>
                                    <input type="text" className='form-control' placeholder='שם' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className='col-md-6 p-2'>
                                    <input type="number" className='form-control' placeholder='טלפון' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className='col-md-6 p-2'>
                                    <input type="text" className='form-control' placeholder='אימייל' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className='col-md-6 p-2'>
                                    <input type="number" className='form-control' placeholder='סטטוס' value={status} onChange={(e) => setStatus(e.target.value)} />
                                </div>
                                <div className='col-md-12 p-2'>
                                </div>


                                {errors && (
                                    <div className="text-danger small">
                                        {errors.phone && errors.phone.map((errorMessage, index) => (
                                            <span key={index}>{errorMessage}</span>
                                        ))}
                                        {errors.name && errors.name.map((errorMessage, index) => (
                                            <span key={index}>{errorMessage}</span>
                                        ))}
                                    </div>
                                )}

                            </div>
                            <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-hover'>
                                        <tbody>
                                            {contacts.map(contact => (
                                                <tr key={contact.id}>
                                                    <td>{contact.name}</td>
                                                    <td>{contact.email}</td>
                                                    <td>
                                                        <div className="h-100 d-flex align-items-center justify-content-center">
                                                            <div className='px-1'>
                                                                <button className='btn btn-basic bg-gray shadow-sm' onClick={() => { handleAddLead(contact) }}>
                                                                    <FontAwesomeIcon icon={faPlus} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer border-0'>
                            <button className='btn btn-primary rounded'>יצירת עובד חדש</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddLeadModal