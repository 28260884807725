import React, { useState, useEffect } from 'react';
import { getOrders, deleteOrder } from '../../api/orders';
import { Button } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

function Orders() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage] = useState(10);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const fetchedOrders = await getOrders();
                setOrders(fetchedOrders);
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const handleSendWhatsAppMessage = (order) => {
        const supplierPhone = order.supplier?.phone;
        if (!supplierPhone) {
            alert('Supplier phone number is not available');
            return;
        }

        const productDetails = order.products.map(product => `${product.name} (Qty: ${product.pivot.quantity})`).join('\n');
        const message = `Order Number: ${order.order_number}\n\nProducts:\n${productDetails}`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${supplierPhone}?text=${encodedMessage}`;

        window.open(whatsappURL, '_blank');
    };

    const handleDeleteOrder = async (orderId) => {
        try {
            await deleteOrder(orderId);
            setOrders(orders.filter(order => order.id !== orderId));
            alert('Order deleted successfully!');
        } catch (error) {
            console.error('Error deleting order:', error);
            alert('Failed to delete order');
        }
    };

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container">
            <h2 className="my-4">רשימת הזמנות</h2>
            {loading ? (
                <p>טוען הזמנות...</p>
            ) : (
                <>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>מספר הזמנה</th>
                                <th>שם ספק</th>
                                <th>טלפון ספק</th>
                                <th>אימייל ספק</th>
                                <th>מוצרים</th>
                                <th>תאריך יצירת הזמנה</th>
                                <th>פעולות</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentOrders.map((order) => (
                                <tr key={order.id}>
                                    <td>{order.order_number}</td>
                                    <td>{order.supplier?.name}</td> 
                                    <td>{order.supplier?.phone}</td> 
                                    <td>{order.email}</td>
                                    <td>
                                        {order.products.map((product, index) => (
                                            <div key={index}>
                                                {product.name} (כמות יחידות: {product.pivot.quantity})
                                            </div>
                                        ))}
                                    </td>
                                    <td>{formatDate(order.created_at)}</td>
                                    <td>
                                        <Button
                                            variant="success"
                                            onClick={() => handleSendWhatsAppMessage(order)}
                                        >
                                            Send WhatsApp
                                        </Button>
                                        {' '}
                                        <Button
                                            variant="danger"
                                            onClick={() => handleDeleteOrder(order.id)}
                                        >
                                            הסרה
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination>
                        {Array.from({ length: Math.ceil(orders.length / ordersPerPage) }).map((_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </>
            )}
        </div>
    );
}

export default Orders;
