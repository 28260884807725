import React from 'react';
import { deleteInventory } from '../../api/inventory';
import { useTranslation } from 'react-i18next';

function DeleteProductModal({ show, product, setShow, setProducts }) {
    const { t } = useTranslation();

    const handleClose = () => setShow(false);

    const handleDelete = async () => {
        try {
            await deleteInventory(product.id);
            setProducts((prevProducts) => prevProducts.filter((p) => p.id !== product.id));
            setShow(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={`modal fade ${show ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                <div className="modal-content py-3 px-4 border-0 shadow-lg">
                    <div className="modal-header pb-0 border-0 d-flex align-items-center">
                        <div>
                            <h4 className="modal-title bold m-0">{t('actions.delete_product')}</h4>
                        </div>
                        <span type="button" className="close ms-auto m-0 text-secondary" onClick={handleClose} style={{ fontSize: '25pt', fontWeight: '300' }}>
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                    <div className='modal-body'>
                        <p>{t('actions.confirm_delete')} {product.name}?</p>
                    </div>
                    <div className='modal-footer border-0'>
                        <button className='btn btn-danger rounded' onClick={handleDelete}>{t('actions.delete')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteProductModal;
