import { apiUrl } from './config';

const getInventory = async () => {
    try {
       
        const response = await fetch(apiUrl + '/inventory', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
         
            },
        });

        // Check if the response is ok (status code 200-299)
        // if (!response.ok) {
        //     window.location.href = '/login'; // Redirect to login page if unauthenticated
        //     return;
        // }

        // Try to parse the JSON response
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        // Catch and handle any errors
        console.error('Error fetching inventory:', error);
        throw error; // Re-throw the error after logging it
    }
}

const updateInventory = async (product) => {
    try {
        const token = localStorage.getItem('accessToken'); 
        const response = await fetch(apiUrl + '/inventory/' + product.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(product)
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error;
    }
}

const deleteInventory = async (product_id) => {
    try {
        const token = localStorage.getItem('accessToken'); 
        const response = await fetch(apiUrl + '/inventory/' + product_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error;
    }
}
const generateQrCode = async (product_id) => {
    try {
        const token = localStorage.getItem('accessToken'); 
        const response = await fetch(apiUrl + `/products/${product_id}/generate-qr-code`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();
        return responseData.qr_code;
    } catch (error) {
        throw new Error(error);
    }
}

export { getInventory, updateInventory, deleteInventory , generateQrCode};
