import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getInventory, updateInventory, deleteInventory, generateQrCode } from '../api/inventory';
import UpdateProductModal from './product/UpdateProductModal';
import DeleteProductModal from './product/DeleteProductModal';

function ProductTable() {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            const data = await getInventory();
            setProducts(data);
            setFilteredProducts(data);
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        filterProducts();
    }, [searchTerm, selectedSupplier, products]);

    const filterProducts = () => {
        let filtered = products;

        if (searchTerm) {
            filtered = filtered.filter(product =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (selectedSupplier) {
            filtered = filtered.filter(product => product.supplier.name === selectedSupplier);
        }

        setFilteredProducts(filtered);
    };

    const handleUpdateClick = (product) => {
        setSelectedProduct(product);
        setShowUpdateModal(true);
    };

    const handleDeleteClick = (product) => {
        setSelectedProduct(product);
        setShowDeleteModal(true);
    };

    const handleGenerateQrCode = async (product) => {
        try {
            const qrCode = await generateQrCode(product.id);
            setProducts(products.map(p => p.id === product.id ? { ...p, qr_code: qrCode } : p));
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    };

    const uniqueSuppliers = [...new Set(products.map(product => product.supplier.name))];

    return (
        <div className="bg-white rounded p-3 shadow-sm">
            <h6 className="bold mb-3">{t('products.product_list')}</h6>
            <div className="mb-3">
                <input
                    type="text"
                    placeholder={t('products.search')}
                    className="form-control"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <select
                    className="form-control"
                    value={selectedSupplier}
                    onChange={(e) => setSelectedSupplier(e.target.value)}
                >
                    <option value="">{t('products.all_suppliers')}</option>
                    {uniqueSuppliers.map((supplier, index) => (
                        <option key={index} value={supplier}>
                            {supplier}
                        </option>
                    ))}
                </select>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{t('products.supplier_name')}</th>
                        <th>{t('products.name')}</th>
                        <th>מלאי במחסן</th>
                        <th>{t('products.maxquantity')}</th>
                        <th>{t('products.price')}</th>
                        <th>{t('products.phone')}</th>
                        <th>QR  קוד</th>
                        <th>{t('actions.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredProducts.map((product, index) => (
                        <tr key={index}>
                            <td>{product.supplier.name}</td>
                            <td>{product.name}</td>
                            <td>{product.quantity}</td>
                            <td>{product.maxquantity}</td>
                            <td>{product.price}</td>
                            <td>{product.supplier.phone}</td>
                            <td>
                                {product.qr_code ? (
                                    <img
                                        src={`data:image/png;base64,${product.qr_code}`}
                                        alt={`QR code for ${product.name}`}
                                        style={{ width: '100px', height: '100px' }}
                                    />
                                ) : (
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleGenerateQrCode(product)}
                                    >
                                        צור QR code
                                    </button>
                                )}
                            </td>
                            <td>
                                <button className="btn btn-primary me-2 fa fa-edit" onClick={() => handleUpdateClick(product)}>
                                   
                                </button>
                                <button className="btn btn-danger m-2 fa fa-trash" onClick={() => handleDeleteClick(product)}>
                                   
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {showUpdateModal && selectedProduct && (
                <UpdateProductModal
                    show={showUpdateModal}
                    product={selectedProduct}
                    setShow={setShowUpdateModal}
                    setProducts={setProducts}
                />
            )}
            {showDeleteModal && selectedProduct && (
                <DeleteProductModal
                    show={showDeleteModal}
                    product={selectedProduct}
                    setShow={setShowDeleteModal}
                    setProducts={setProducts}
                />
            )}
        </div>
    );
}

export default ProductTable;
