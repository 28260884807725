import React, { useState, useEffect } from 'react';
import { getUserInfo } from '../../api/user';
import { getProjectInfo } from '../../api/project';
import { getSuppliers } from '../../api/suppliers';
import { getInvoices } from '../../api/invoices';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import InvoiceUploadModal from '../../components/collection/InvoiceUploadModal';
import Calendar from 'react-calendar';
import { Line } from 'react-chartjs-2';

function Home() {
    const { t } = useTranslation();
    const [projectInfo, setProjectInfo] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [value, onChange] = useState(new Date());
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
            } catch (error) {
                console.error("Error fetching user info:", error);
            }
        };

        const fetchProjectInfo = async () => {
            try {
                const fetchedProjectInfo = await getProjectInfo();
                setProjectInfo(fetchedProjectInfo);
            } catch (error) {
                console.error('Error fetching project info:', error);
            }
        };

        const fetchSuppliers = async () => {
            try {
                const fetchedSuppliers = await getSuppliers();
                setSuppliers(fetchedSuppliers);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        const fetchInvoices = async () => {
            try {
                const fetchedInvoices = await getInvoices();
                setInvoices(fetchedInvoices);
                setFilteredInvoices(fetchedInvoices);
                console.log('filteredInvoices',fetchedInvoices)
            } catch (error) {
                console.error('Error fetching invoices:', error);
            }
        };

        fetchUserInfo();
        fetchProjectInfo();
        fetchSuppliers();
        fetchInvoices();
    }, []);

    useEffect(() => {
        filterInvoices();
    }, [selectedYear, selectedMonth, invoices]);

    const calculateTotalAmountForCurrentMonth = () => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        return invoices
            .filter(invoice => invoice.month === currentMonth && invoice.year === currentYear)
            .reduce((total, invoice) => total + parseFloat(invoice.amount), 0);
    };
    

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const filterInvoices = () => {
        let filtered = invoices;

        if (selectedYear) {
            filtered = filtered.filter(invoice => invoice.year.toString() === selectedYear);
        }

        if (selectedMonth) {
            filtered = filtered.filter(invoice => invoice.month.toString() === selectedMonth);
        }

        setFilteredInvoices(filtered);
    };

    const lineChartData = {
        labels: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
        datasets: [
            {
                label: 'סכום הוצאות לפי חודש',
                data: [1, 3, 10, 7, 8, 12, 15, 15, 13, 14, 12, 17, 21, 23, 18],
                fill: true,
                backgroundColor: 'rgba(0,110,220, 0.2)',
                borderColor: 'rgb(0,110,220)',
                tension: 0.4,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div dir="rtl" className="container">
            <div className='pt-3'>
                <h3 className='mt-3 fw-500'>{t('greeting.welcome')}, {userInfo && userInfo.name.split(" ")[0]}!</h3>
            </div>

            <div className="row pt-3">
                <div className="col-md-4">
                    <div className="bg-white rounded p-3 shadow-sm">
                        <h5>סה"כ הכסף שיש לשלם החודש</h5>
                        <h6>{calculateTotalAmountForCurrentMonth().toLocaleString()}</h6>
                    </div>
                    {/* <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <h5>הכסף ששולם</h5>
                        <h6>23,481</h6>
                    </div> */}
                </div>
                <div className="col-md-4">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <Button variant="primary" onClick={() => setShowInvoiceModal(true)}>
                            הוסף הוצאה
                        </Button>
                        <InvoiceUploadModal
                            show={showInvoiceModal}
                            handleClose={() => setShowInvoiceModal(false)}
                            suppliers={suppliers}
                            setInvoices={setInvoices}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <div className="d-flex">
                            <div className="me-3">
                                <label>שנה:</label>
                                <select value={selectedYear} onChange={handleYearChange} className="form-select">
                                    <option value="">בחר שנה</option>
                                    {[...new Set(invoices.map(invoice => invoice.year))].map((year, index) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>חודש:</label>
                                <select value={selectedMonth} onChange={handleMonthChange} className="form-select">
                                    <option value="">בחר חודש</option>
                                    {Array.from({ length: 12 }, (_, index) => index + 1).map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <h5 className="mt-3">קבלות</h5>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>מספר</th>
                                    <th>עלות</th>
                                    <th>הקובץ</th>
                                    <th>ספק</th>
                                    <th>תאריך תשלום</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredInvoices.map((invoice, index) => (
                                    <tr key={index}>
                                        <td>{invoice.id}</td>
                                        <td>{invoice.amount}</td>
                                        <td><a href={invoice.file_url} target="_blank" rel="noopener noreferrer">הורד קובץ</a></td>
                                        <td>{invoice.supplier_name}</td>
                                        <td>{formatDate(invoice.date)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
