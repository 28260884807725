import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getInventory } from '../../api/inventory';
import { saveOrder } from '../../api/orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewOrder() {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [orderProducts, setOrderProducts] = useState({});
    const [supplierDetails, setSupplierDetails] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            const data = await getInventory();
            setProducts(data);
            setFilteredProducts(data);
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        filterProducts();
    }, [searchTerm, selectedSupplier, products]);

    const filterProducts = () => {
        let filtered = products;

        if (searchTerm) {
            filtered = filtered.filter(product =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (selectedSupplier) {
            filtered = filtered.filter(product => product.supplier_name === selectedSupplier);
        }

        setFilteredProducts(filtered);
    };

    const handleStockChange = (productId, value) => {
        const updatedQuantity = parseInt(value) || 0;
        
        // Update the quantity in the products list
        setProducts(products.map(product =>
            product.id === productId ? { ...product, quantity: updatedQuantity } : product
        ));
        
        // Update the quantity in the orderProducts list if the product is already added
        if (orderProducts[productId]) {
            setOrderProducts({
                ...orderProducts,
                [productId]: { ...orderProducts[productId], quantity: updatedQuantity }
            });
        }
    };

    const handleAddProduct = (product) => {
        if (!supplierDetails) {
            setSupplierDetails({
                supplier_id: product.supplier_id,
                supplier_name: product.supplier_name
            });
        }

        // Add product to orderProducts with the current stock quantity
        setOrderProducts({
            ...orderProducts,
            [product.id]: { ...product, quantity: product.quantity }
        });
    };

    const handleQuantityChange = (productId, value) => {
        setOrderProducts({
            ...orderProducts,
            [productId]: { ...orderProducts[productId], quantity: parseInt(value) || 0 }
        });
    };

    const handleSendOrder = async () => {
        if (!supplierDetails) {
            alert(t('order.select_supplier'));
            return;
        }

        const orderData = {
            supplier_id: supplierDetails.supplier_id,
            order_number: `ORD-${Date.now()}`,
            products: Object.keys(orderProducts).map(id => ({
                 product_id: id,
                quantity: orderProducts[id].quantity,
            })).filter(product => product.quantity > 0), // Only include products with quantities
        };

        if (orderData.products.length === 0) {
            alert(t('order.no_products_selected'));
            return;
        }

        try {
            const response = await saveOrder(orderData);

            if (response.status === 'success') {
                toast.success(t('order.success_message'), {
                    onClose: () => window.location.reload()
                });
            } else {
                toast.error(t('order.error_message'));
            }
        } catch (error) {
            toast.error(t('order.error_message'));
        }
    };

    const uniqueSuppliers = [...new Set(products.map(product => product.supplier_name))];

    return (
        <div dir="rtl" className='container-fluid' style={{ backgroundColor: '#F3F3F5', minHeight: '100vh' }}>
            <ToastContainer />
            <div className="row">
                <div className="col-md-6 bg-white p-4">
                    <div className='pt-3 pb-5'>
                        <span className='py-3 h3 bold'>{t('greeting.welcome_back')}!</span>
                    </div>
                    <div className='mb-3'>
                        <input
                            type="text"
                            placeholder={t('products.search')}
                            className="form-control bg-transparent py-3 border-0"
                            style={{ backgroundColor: '#EBE9F9' }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className='mb-3'>
                        <select
                            className="form-control bg-transparent py-3 border-0"
                            style={{ backgroundColor: '#EBE9F9' }}
                            value={selectedSupplier}
                            onChange={(e) => setSelectedSupplier(e.target.value)}
                        >
                            <option value="">{t('products.all_suppliers')}</option>
                            {uniqueSuppliers.map((supplier, index) => (
                                <option key={index} value={supplier}>
                                    {supplier}
                                </option>
                            ))}
                        </select>
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>{t('products.supplier_name')}</th>
                                <th>{t('products.name')}</th>
                                <th>מלאי קיים</th> {/* Column for current stock input */}
                                <th>{t('products.actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProducts.map((product, index) => (
                                <tr key={index}>
                                    <td>{product.supplier_name}</td>
                                    <td>{product.name}</td>
                                    <td>
                                        <input 
                                            type="number" 
                                            min="0" 
                                            value={product.quantity} 
                                            onChange={(e) => handleStockChange(product.id, e.target.value)} 
                                            className="form-control" 
                                            style={{ width: '80px', display: 'inline-block' }} 
                                        />
                                    </td> {/* Input field for current stock */}
                                    <td>
                                        <button className="btn btn-primary" onClick={() => handleAddProduct(product)}>
                                            {t('actions.add')}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6 bg-white p-4">
                    <h4 className="bold mb-3">{t('order.new_order')}</h4>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>{t('products.supplier_name')}</th>
                                <th>{t('products.name')}</th>
                                <th>כמות למלאי</th> {/* Column for editing the required quantity */}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(orderProducts).map((product, index) => (
                                <tr key={index}>
                                    <td>{product.supplier_name}</td>
                                    <td>{product.name}</td>
                                    <td>
                                        <input 
                                            type="number" 
                                            min="1" 
                                            value={product.quantity} 
                                            onChange={(e) => handleQuantityChange(product.id, e.target.value)} 
                                            className="form-control" 
                                            style={{ width: '80px', display: 'inline-block' }} 
                                        />
                                    </td> {/* Input field for adjusting the required quantity */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="btn btn-success mt-4" onClick={handleSendOrder}>
                        {t('order.send_order')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NewOrder;
