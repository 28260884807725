import React, { useState, useEffect } from 'react';
import { updateSupplier } from '../api/suppliers';
import { useTranslation } from 'react-i18next';

function UpdateSupplierModal({ supplier, setSupplier, showUpdateSupplierModal, setShowUpdateSupplierModal }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (supplier) {
            setName(supplier.name);
            setEmail(supplier.email);
            setPhone(supplier.phone);
        }
    }, [supplier]);

    const handleCloseUpdateSupplierModal = () => {
        setShowUpdateSupplierModal(false);
    };

    const handleSubmit = async () => {
        const updatedSupplier = {
            id: supplier.id,
            name,
            email,
            phone,
        };

        try {
            const response = await updateSupplier(updatedSupplier);
            setSupplier(response);
            setShowUpdateSupplierModal(false);
            setErrors([]);
        } catch (error) {
            setErrors(error.message);
        }
    };

    return (
        <div className={`modal fade ${showUpdateSupplierModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                <div className="modal-content py-3 px-4 border-0 shadow-lg" style={{ maxHeight: 800, overflow: 'auto' }}>
                    <div className="modal-header pb-0 border-0 d-flex align-items-center">
                        <div>
                            <h4 className="modal-title bold m-0">{t('עדכן ספק')}</h4>
                        </div>
                        <span type="button" className="close ms-auto m-0 text-secondary" onClick={handleCloseUpdateSupplierModal} style={{ fontSize: '25pt', fontWeight: '300' }}>
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className='col-md-6 p-2'>
                                <input type="text" className='form-control' placeholder={t('שם')} value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input type="email" className='form-control' placeholder={t('אימייל')} value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input type="text" className='form-control' placeholder={t('טלפון')} value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            {errors && (
                                <div className="text-danger small">
                                    {errors.description && errors.description.map((errorMessage, index) => (
                                        <span key={index}>{errorMessage}</span>
                                    ))}
                                    {errors.name && errors.name.map((errorMessage, index) => (
                                        <span key={index}>{errorMessage}</span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='modal-footer border-0'>
                        <button className='btn btn-primary rounded' onClick={handleSubmit}>{t('עדכן ספק')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateSupplierModal;
