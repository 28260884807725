import React from 'react';
import { useTranslation } from 'react-i18next';

function ViewSupplierModal({ show, setShow, selectedSupplier }) {
    const { t } = useTranslation();

    const handleClose = () => setShow(false);

    return (
        <div className={`modal fade ${show ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                <div className="modal-content py-3 px-4 border-0 shadow-lg">
                    <div className="modal-header pb-0 border-0 d-flex align-items-center">
                        <h4 className="modal-title bold m-0">פרטי ספק</h4>
                        <span className="close ms-auto m-0 text-secondary" onClick={handleClose} style={{ fontSize: '25pt', fontWeight: '300' }}>
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className='col-md-6 p-2'>
                                <label>{t('suppliers.name')}</label>
                                <p>{selectedSupplier.name}</p>
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('suppliers.email')}</label>
                                <p>{selectedSupplier.email}</p>
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('suppliers.phone')}</label>
                                <p>{selectedSupplier.phone}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewSupplierModal;
