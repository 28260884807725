import React, { useState, useEffect } from 'react';

const EmployeeEarningsTable = () => {
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        // Hardcoded data
        const data = [
            { employee_name: "מחמוד פוואז", sales_july: 232.84, sales_june: 98.47, sales_may: 251.57, sales_april: 540.17, sales_march: 354289, sales_february: 391156, sales_january: 341334 },
            { employee_name: "מוחמד", sales_july: 88103.04, sales_june: 66268.44, sales_may: 83832.23, sales_april: 108763.69, sales_march: 83832.23, sales_february: 108763.69, sales_january: 87287.42 },
            { employee_name: "סרגיי", sales_july: 13035.12, sales_june: 9693.09, sales_may: 11104.2, sales_april: 13727.14, sales_march: 11104.2, sales_february: 13727.14, sales_january: 11116.78 },
        ];
        setEmployees(data);
    }, []);

    return (
        <div className="bg-white rounded p-3 shadow-sm">
            <h6 className="bold mb-3">טבלת רווחי עובדים</h6>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>שם העובד</th>
                        <th>יולי</th>
                        <th>יוני</th>
                        <th>מאי</th>
                        <th>אפריל</th>
                        <th>מרץ</th>
                        <th>פברואר</th>
                        <th>ינואר</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map((employee, index) => (
                        <tr key={index}>
                            <td>{employee.employee_name}</td>
                            <td>{employee.sales_july}</td>
                            <td>{employee.sales_june}</td>
                            <td>{employee.sales_may}</td>
                            <td>{employee.sales_april}</td>
                            <td>{employee.sales_march}</td>
                            <td>{employee.sales_february}</td>
                            <td>{employee.sales_january}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EmployeeEarningsTable;
