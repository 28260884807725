import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { updateInventory } from '../api/inventory';

function ReceivingGoodsModal({ products, setProducts, showReceivingGoodsModal, setShowReceivingGoodsModal }) {
    const { t } = useTranslation();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [errors, setErrors] = useState([]);

    const handleClose = () => {
        setShowReceivingGoodsModal(false);
    };

    const handleQuantityChange = (productId, quantity) => {
        setQuantities({ ...quantities, [productId]: quantity });
    };

    const handleAddProduct = (product) => {
        if (!selectedProducts.some(p => p.id === product.id)) {
            setSelectedProducts([...selectedProducts, product]);
        }
    };

    const handleRemoveProduct = (productId) => {
        setSelectedProducts(selectedProducts.filter(product => product.id !== productId));
        const updatedQuantities = { ...quantities };
        delete updatedQuantities[productId];
        setQuantities(updatedQuantities);
    };

    const handleSave = async () => {
        const updatedProducts = products.map(product => {
            if (selectedProducts.includes(product)) {
                return { ...product, quantity: (product.quantity || 0) + (quantities[product.id] || 0) };
            }
            return product;
        });

        try {
            for (const product of selectedProducts) {
                const updatedProduct = {
                    ...product,
                    quantity: (product.quantity || 0) + (quantities[product.id] || 0)
                };
                await updateInventory(updatedProduct);
            }
            setProducts(updatedProducts);
            setShowReceivingGoodsModal(false);
        } catch (error) {
            setErrors([error.message]);
        }
    };

    const filterProducts = () => {
        if (!searchTerm) return products;
        return products.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    return (
        <Modal size="lg" show={showReceivingGoodsModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('קבלת סחורה')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-6'>
                        <h5>מוצרים שנבחרו</h5>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{t('products.name')}</th>
                                    <th>{t('products.quantity')}</th>
                                    <th>כמות להוסיף</th>
                                    <th>הסרה</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProducts.map((product, index) => (
                                    <tr key={index}>
                                        <td>{product.name}</td>
                                        <td>{product.quantity}</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                className="form-control"
                                                value={quantities[product.id] || 1}
                                                onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <button className="btn btn-danger" onClick={() => handleRemoveProduct(product.id)}>
                                                הסר
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div>
                          <Button variant="primary" onClick={handleSave}>
                          הוספת מוצרים
                          </Button>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='mb-3'>
                            <input
                                type="text"
                                placeholder={t('products.search')}
                                className="form-control"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{t('products.name')}</th>
                                    <th>קיים במחסן</th>
                                    <th>כמות</th>
                                    <th>{t('actions.add')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterProducts().map((product, index) => (
                                    <tr key={index}>
                                        <td>{product.name}</td>
                                        <td>{product.maxquantity}</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                className="form-control"
                                                value={quantities[product.id] || 1}
                                                onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <button className="btn btn-primary" onClick={() => handleAddProduct(product)}>
                                                {t('actions.add')}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {errors.length > 0 && (
                    <div className="text-danger small mt-3">
                        {errors.map((errorMessage, index) => (
                            <div key={index}>{errorMessage}</div>
                        ))}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    סגירה
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    הוספת מוצרים
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ReceivingGoodsModal;
