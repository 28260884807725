import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header/Header';
import { getProducts } from '../../api/products';
import { getSuppliers } from '../../api/suppliers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddProductModal from '../../components/AddProductModal';
import AddOrderModal from '../../components/AddOrderModal';
import UpdateSupplierModal from '../../components/UpdateSupplierModal';
import ReceivingGoodsModal from '../../components/ReceivingGoodsModal'; // Import the ReceivingGoodsModal component
import { useTranslation } from 'react-i18next';
import ProductTable from '../../components/ProductTable';

function Inventory() {
    const [products, setProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [showAddOrderModal, setShowAddOrderModal] = useState(false);
    const [showUpdateSupplierModal, setShowUpdateSupplierModal] = useState(false);
    const [showReceivingGoodsModal, setShowReceivingGoodsModal] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchedProducts = async () => {
            try {
                const fetchedProducts = await getProducts();
                setProducts(fetchedProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchedSuppliers = async () => {
            try {
                const fetchedSuppliers = await getSuppliers();
                setSuppliers(fetchedSuppliers);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchedProducts();
        fetchedSuppliers();
    }, []);

    const openAddProductModal = () => {
        setShowAddProductModal(true);
    };

    const openAddOrderModal = () => {
        setShowAddOrderModal(true);
    };

    const openReceivingGoodsModal = () => {
        setShowReceivingGoodsModal(true);
    };

    const openUpdateSupplierModal = (supplier) => {
        setSelectedSupplier(supplier);
        setShowUpdateSupplierModal(true);
    };

    const handleNewOrderClick = () => {
        navigate('/newOrder');
    };

    return (
        <>
            <div className='d-flex justify-content-center pt-3 pb-4'>
                <button className='btn btn-basic bg-white shadow-sm medium' onClick={openAddProductModal}>
                    <span className='text-primary'>
                        <FontAwesomeIcon icon={faPlus} className='pe-1' /> הוספת מוצר
                    </span>
                </button>
                <button className='btn btn-basic bg-white shadow-sm medium ms-2' onClick={handleNewOrderClick}>
                    <span className='text-primary'>
                        <FontAwesomeIcon icon={faPlus} className='pe-1' /> הזמנה חדשה
                    </span>
                </button>
                <button className='btn btn-basic bg-white shadow-sm medium ms-2' onClick={openReceivingGoodsModal}>
                    <span className='text-primary'>
                        <FontAwesomeIcon icon={faPlus} className='pe-1' /> קבלת סחורה
                    </span>
                </button>
            </div>

            <AddProductModal
                products={products}
                setProducts={setProducts}
                showAddProductModal={showAddProductModal}
                setShowAddProductModal={setShowAddProductModal}
            />
            <AddOrderModal
                orders={[]} // You'll need to handle the orders state similarly to products
                setOrders={() => {}} // Update this to handle setting orders
                showAddOrderModal={showAddOrderModal}
                setShowAddOrderModal={setShowAddOrderModal}
            />
            <UpdateSupplierModal
                supplier={selectedSupplier}
                setSupplier={(updatedSupplier) => {
                    setSuppliers((prevSuppliers) => prevSuppliers.map(s => s.id === updatedSupplier.id ? updatedSupplier : s));
                }}
                showUpdateSupplierModal={showUpdateSupplierModal}
                setShowUpdateSupplierModal={setShowUpdateSupplierModal}
            />
            <ReceivingGoodsModal
                products={products}
                setProducts={setProducts}
                showReceivingGoodsModal={showReceivingGoodsModal}
                setShowReceivingGoodsModal={setShowReceivingGoodsModal}
            />

            <ProductTable />
        </>
    );
}

export default Inventory;
