import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { saveInvoice } from '../../api/invoices';
import { getSuppliers } from '../../api/suppliers';

function InvoiceUploadModal({ show, handleClose, setInvoices }) {
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [amount, setAmount] = useState('');
    const [supplier, setSupplier] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [errors, setErrors] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const suppliersData = await getSuppliers();
                setSuppliers(suppliersData);
            } catch (error) {
                setErrors([error.message]);
            }
        };

        fetchSuppliers();
    }, []);

    const handleFileChange = (e) => setFile(e.target.files[0]);
    const handleAmountChange = (e) => setAmount(e.target.value);
    const handleSupplierChange = (e) => setSupplier(e.target.value);
    const handleDueDateChange = (e) => setDueDate(e.target.value);

    const handleSave = async () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('amount', amount);
        formData.append('supplier_id', supplier);
        formData.append('date', dueDate);

        try {
            const response = await saveInvoice(formData);
    
            if (response.status === 200 || response.status === 201) {
                // Assuming the response includes the created invoice
                const invoice = response.data; // or response.invoice based on your API structure
                setInvoices((prev) => [...prev, invoice]);
                handleClose();
            } else if (response.errors) {
                setErrors(response.errors);
            } else {
                handleClose();
            }
        } catch (error) {
            setErrors([error.message || 'An unexpected error occurred.']);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>אעלה קבלה</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>הוסף קובץ</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>סכום</Form.Label>
                        <Form.Control type="number" value={amount} onChange={handleAmountChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>ספק</Form.Label>
                        <Form.Control as="select" value={supplier} onChange={handleSupplierChange}>
                            <option value="">בחר ספק</option>
                            {suppliers.map((sup, index) => (
                                <option key={index} value={sup.id}>{sup.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>תאריך לתשלום</Form.Label>
                        <Form.Control type="date" value={dueDate} onChange={handleDueDateChange} />
                    </Form.Group>
                    {errors.length > 0 && (
                        <div className="text-danger small mt-3">
                            {errors.map((error, index) => (
                                <div key={index}>{error}</div>
                            ))}
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    ביטול
                </Button>
                <Button variant="primary" onClick={handleSave}>
                  שמירה
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default InvoiceUploadModal;
