import React, { useState } from 'react';
import { updateInventory } from '../../api/inventory';
import { useTranslation } from 'react-i18next';

function UpdateProductModal({ show, product, setShow, setProducts }) {
    const { t } = useTranslation();
    const [name, setName] = useState(product.name);
    const [quantity, setQuantity] = useState(product.quantity);
    const [maxquantity, setMaxquantity] = useState(product.maxquantity);
    const [price, setPrice] = useState(product.price);
    const [barcode, setBarcode] = useState(product.barcode_image);
    const [errors, setErrors] = useState([]);

    const handleClose = () => setShow(false);

    const handleSubmit = async () => {
        const updatedProduct = {
            ...product,
            name,
            quantity,
            maxquantity,
            price,
            // barcode_image: barcode,
        };

        try {
            const updated = await updateInventory(updatedProduct);
            setProducts((prevProducts) => prevProducts.map((p) => (p.id === updated.id ? updated : p)));
            setShow(false);
        } catch (error) {
            setErrors(error.message);
        }
    };

    return (
        <div className={`modal fade ${show ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                <div className="modal-content py-3 px-4 border-0 shadow-lg">
                    <div className="modal-header pb-0 border-0 d-flex align-items-center">
                        <div>
                            <h4 className="modal-title bold m-0">{t('actions.update_product')}</h4>
                        </div>
                        <span type="button" className="close ms-auto m-0 text-secondary" onClick={handleClose} style={{ fontSize: '25pt', fontWeight: '300' }}>
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className='col-md-6 p-2'>
                            <label htmlFor="">שם המוצר</label>
                                <input type="text" className='form-control' placeholder={t('products.name')} value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                            <label htmlFor="">כמות קיים במלאי</label>
                                <input type="text" className='form-control' placeholder={t('products.quantity')} value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label htmlFor="">כמות מקסימלי</label>
                                <input type="text" className='form-control' placeholder={t('products.maxquantity')} value={maxquantity} onChange={(e) => setMaxquantity(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                            <label htmlFor="">מחיר ליחידה</label>
                                <input type="number" className='form-control' placeholder={t('products.price')} value={price} onChange={(e) => setPrice(e.target.value)} />
                            </div>
                            {/* <div className='col-md-6 p-2'>
                                <input type="text" className='form-control' placeholder={t('products.barcode')} value={barcode} onChange={(e) => setBarcode(e.target.value)} />
                            </div> */}
                        </div>
                        {errors && (
                            <div className="text-danger small">
                                {errors.map((errorMessage, index) => (
                                    <span key={index}>{errorMessage}</span>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='modal-footer border-0'>
                        <button className='btn btn-primary rounded' onClick={handleSubmit}>{t('actions.update')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateProductModal;
